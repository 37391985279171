<template>
  <el-main class="rh-table">
    <h1><i class="el-icon-setting" /> Options de formation continue</h1>
    <p>
      Les formations continues attribuées à des médecins ne peuvent pas êtres
      supprimées.<br />Celles faisant partie d'une checklist ne peuvent être ni
      éditées ni supprimées.
    </p>
    <app-table
      tablenom="fcontinuetitres"
      :cols="colonnes"
      :items="fcontinuetitres"
      :loadstatus="fcontinuetitresLoadStatus"
      :deletestatus="fcontinuetitreDeleteStatus"
      :formroutes="fctFormRoutes"
      :actions="fctActions"
    >
    </app-table>
  </el-main>
</template>
<script>
import AppTable from "@/components/rh/ui/table/AppTable";

export default {
  components: { "app-table": AppTable },
  data() {
    return {
      fctFormRoutes: {
        edit: { route: "fcontinuetitreedit", params: { idfctitre: "id" } },
        create: { route: "fcontinuetitrecreate" },
      },
    };
  },
  computed: {
    fcontinuetitresLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.fctit.titresLoadStatus;
    },
    fcontinuetitreDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.fctit.titreDeleteStatus;
    },
    fcontinuetitres() {
      return this.$store.state.fctit.titres;
    },
    colonnes() {
      return this.$store.state.fctit.colonnes;
    },
    fctActions() {
      return this.$store.state.fctit.actions;
    },
  },
};
</script>
